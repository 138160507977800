/**
 * 
 * @param {Function} fn 
 * @param {interval} interval 
 */
function throttle(fn, interval) {
    var lastCall, timeoutId;

    return function () {
        var now = new Date().getTime();
        if (lastCall && now < (lastCall + interval)) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function () {
                lastCall = now;
                fn.call();
            }, interval - (now - lastCall));
        } else {
            lastCall = now;
            fn.call();
        }
    };
}

/**
 * Run the callback when the document is loaded.
 * 
 * @param {Function} callback 
 */
function documentReady(callback) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(callback, 1);
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

/**
 * Highlight nav menu items on scroll.
 * 
 * The container with the scroll should have the following attributes:
 * <div class="js-highlight-nav" data-target="navbar" ...> ... </div>
 * 
 * It should also have the CSS attribute "position: relative;" or "position: absolute;"
 * 
 * The value of the attribute "data-target" is the ID of the nav bar containing the links to the
 * different sections of the scrollable container. When a linked section is in view, the corresponding link
 * and it's parent will get the class "active".
 * 
 * The scrollable container should have "overflow-y: auto" and a height, to be scrollable.
 * 
 * The sections inside the scrollable container should have an ID which can be scrolled to from the nav bar.
 * They should also have the class "js-section".
 */
(function (win, doc) {
    var highlighters = doc.getElementsByClassName('js-highlight-nav');

    if (!highlighters.length) {
        return false;
    }

    for (var highlighterIndex = 0; highlighterIndex < highlighters.length; highlighterIndex++) {
        var highlighter = highlighters[highlighterIndex];

        if (!highlighter.hasAttribute('data-target')) {
            continue;
        }

        var target = doc.getElementById(highlighter.getAttribute('data-target'));

        if (!target) {
            continue;
        }

        /**
         * Get all sections.
         */
        var sectionCollection = highlighter.getElementsByClassName('js-section'),
            sections = [],
            sectionIdToNavigationLinks = {};

        /**
         * Get sections in reverse order. It is in reverse order so we can abort the iteration as soon
         * as we find a match.
         */
        for (var sectionIndex = sectionCollection.length - 1; sectionIndex >= 0; sectionIndex--) {
            if (sectionCollection[sectionIndex].hasAttribute('id')) {
                sections.push(sectionCollection[sectionIndex]);

                /**
                 * Also save nav links for each section.
                 */
                var id = sectionCollection[sectionIndex].getAttribute('id'),
                    navLinks = target.querySelectorAll('a[href="#' + id + '"]');

                if (navLinks.length) {
                    sectionIdToNavigationLinks[id] = navLinks[0];
                }
            }
        }

        function highlightNavigation() {
            /**
             * Get scroll top for the scrollable container.
             */
            var scrollTop = highlighter.scrollTop ? highlighter.scrollTop : 0;

            for (var sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
                var section = sections[sectionIndex];

                /**
                 * Check the distance from each section to the top of the scrollable container.
                 */
                var sectionTop = section.offsetTop ? section.offsetTop : 0;

                /**
                 * If the section is in view.
                 */
                if (scrollTop >= sectionTop) {
                    var id = section.getAttribute('id'),
                        navLink = sectionIdToNavigationLinks[id];

                    /**
                     * Check if a change is needed.
                     */
                    if (!navLink.classList.contains('active')) {
                        /**
                         * Remove the class "active" from all nav links.
                         */
                        for (id in sectionIdToNavigationLinks) {
                            if (sectionIdToNavigationLinks.hasOwnProperty(id)) {
                                sectionIdToNavigationLinks[id].classList.remove('active');
                                sectionIdToNavigationLinks[id].parentElement.classList.remove('active');
                            }
                        }

                        /**
                         * Add the class "active" to the current nav link.
                         */
                        navLink.classList.add('active');
                        navLink.parentElement.classList.add('active');
                        navLink.scrollIntoView({ behavior: "smooth", block: "end" });
                    }

                    /**
                     * Abort the loop since the correct section was found.
                     */
                    return false;
                }
            }
        }

        /**
         * Run the highlighter on the scroll event, but throttled.
         */
        highlighter.addEventListener('scroll', throttle(highlightNavigation, 100));

        /**
         * Also run the highlighter on resize if another section should happen to scroll into view.
         */
        win.addEventListener('resize', throttle(highlightNavigation, 100));

        /**
         * Highlight on load.
         */
        highlightNavigation();
    }
})(window, document);

/**
 * Handle tab component.
 * 
 * Use the following structure to build your tab view and the script will take care 
 * of the rest. I will also automatically add and handle accessability tags.
 * 
 * <div class="tabs" >
 *     <div class="tabs__buttons">
 *         <button class="tabs__button active">One</button>
 *         <button class="tabs__button">Two</button>
 *         <button class="tabs__button">Three</button>
 *     </div>
 *     <div class="tabs__panes">
 *         <div class="tabs__pane active">Hello</div>
 *         <div class="tabs__pane">Two</div>
 *         <div class="tabs__pane">Three</div>
 *     </div>
 * </div>
 */
(function (win, doc) {
    win.addEventListener('focusin', function (ev) {
        /**
         * Change state of the tab view when a tab button is focused.
         */
        if (ev.target.classList.contains('tabs__button')) {
            var button = ev.target,
                buttonList = button.parentNode,
                tabIndex = Array.prototype.indexOf.call(buttonList.children, button),
                paneList = buttonList.parentNode.parentNode.querySelector('.tabs__panes'),
                activePane = paneList.children[tabIndex];

            Array.prototype.forEach.call(buttonList.children, function (_button) {
                _button.classList.remove('active');
                _button.setAttribute('aria-selected', 'false');
            });

            button.classList.add('active');
            button.setAttribute('aria-selected', 'true');

            Array.prototype.forEach.call(paneList.children, function (_pane) {
                _pane.classList.remove('active');
                _pane.setAttribute('hidden', 'hidden');
            });

            activePane.classList.add('active');
            activePane.removeAttribute('hidden');
        }
    });

    documentReady(function () {
        /**
         * Iterate over each tab view to set the correct starting state on them.
         */
        Array.prototype.forEach.call(doc.querySelectorAll('.tabs'), function (tabs, tabIndex) {
            var buttons = tabs.querySelectorAll('.tabs__button'),
                panes = tabs.querySelectorAll('.tabs__pane'),
                tabList = tabs.querySelector('.tabs__buttons');

            if (tabList) {
                tabList.setAttribute('role', 'tablist');
            }

            /**
             * Iterate over each tab button and pane in the view to set the correct starting state on them.
             */
            for (var index = 0; index < buttons.length && index < panes.length; index++) {
                var button = buttons[index],
                    pane = panes[index],
                    buttonId = 'tab-button-' + tabIndex + '-' + index,
                    paneId = 'tab-pane-' + tabIndex + '-' + index;

                /**
                 * Get or set an ID to the button.
                 */
                if (!button.hasAttribute('id')) {
                    button.setAttribute('id', buttonId);
                } else {
                    buttonId = button.getAttribute('id');
                }

                /**
                 * Get or set an ID to the pane.
                 */
                if (!pane.hasAttribute('id')) {
                    pane.setAttribute('id', paneId);
                } else {
                    paneId = pane.getAttribute('id');
                }

                /**
                 * Add accessability attributes and toggle them if a button 
                 * or pane is already active.
                 */
                button.setAttribute('aria-controls', paneId);
                button.setAttribute('role', 'tab');
                pane.setAttribute('aria-labelledby', buttonId);
                pane.setAttribute('role', 'tabpanel');

                if (button.classList.contains('active')) {
                    button.setAttribute('aria-selected', 'true');
                } else {
                    button.setAttribute('aria-selected', 'false');
                }

                if (pane.classList.contains('active')) {
                    pane.removeAttribute('hidden');
                } else {
                    pane.setAttribute('hidden', 'hidden');
                }
            }
        });
    });

})(window, document);